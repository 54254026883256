import React, { useEffect, useState } from "react"
import BookResults from "../components/BookResults"
import Layout from "../components/Layout"
import { fetchApi } from "../components/utils"

export default function NewsPage({ location }) {
  const [books, setBooks] = useState([])

  const search = new URLSearchParams(location.search)
  const slug = search.get("x")

  useEffect(() => {
    fetchApi(`https://api.institutbiblique.be/recensions?take=20`, setBooks)
  }, [slug])

  return (
    <Layout>
      <BookResults
        title="Nouveautés"
        results={books}
        noResult="Récupération des dernières nouveuatés en cours…"
      />
    </Layout>
  )
}
