export async function fetchApi(path, setFunc, notFoundFunc) {
  const url = new URL(path, "https://api.institutbiblique.be").href
  const res = await fetch(url)
  if (!res.ok) {
    if (res.status === 404) notFoundFunc(res)
    else throw res.status
  } else {
    const data = await res.json()
    setFunc(data)
  }
}

export function filterStartingWith(arr, search) {
  return arr.filter((el) => el.startsWith(search))
}
