import React from "react"
import styled from "styled-components"
import { colors } from "./styles"

const Container = styled.div`
  @media (min-width: 900px) {
    display: flex;
    height: 100%;
  }
`

const Sidebar = styled.div`
  background: ${colors.greyLight};
  padding: 50px 25px;
  font-size: 0.8em;

  @media (min-width: 900px) {
    max-width: 350px;
  }
`

export default function WithSidebar({ main, side }) {
  return (
    <Container>
      <div
        style={{
          flex: "1",
          padding: "100px 0",
        }}
      >
        {main}
      </div>
      <Sidebar>{side}</Sidebar>
    </Container>
  )
}
