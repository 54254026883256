import { Link } from "gatsby"
import React from "react"
import { IoPersonCircleOutline, IoTimeOutline } from "react-icons/io5"
import styled from "styled-components"
import { colors } from "./styles"

const Book = styled(Link)`
  color: ${colors.greyDark};

  &:hover {
    .title {
      color: ${colors.yellow};
      transition: color 0.2s ease;
    }
  }
`

export default function BookResult({ result }) {
  return (
    <Book
      to={"/recension?x=" + result.slug}
      style={{
        display: "flex",
        padding: 10,
        borderRadius: 8,
      }}
    >
      <div
        style={{
          position: "relative",
          background: colors.greyDark,
          width: 140,
          height: 200,
          marginRight: 20,
          borderRadius: 3,
          overflow: "hidden",
        }}
      >
        {result.bookCover && (
          <img
            style={{
              objectFit: "cover",
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
            loading="lazy"
            alt={result.title}
            src={result.bookCover}
          />
        )}
      </div>
      <div style={{ flex: 1 }}>
        <div
          className="title"
          style={{
            fontSize: "1.2em",
            lineHeight: 1.2,
            fontWeight: 600,
            margin: "0.5em 0 0.2em 0",
          }}
        >
          {result.title}
        </div>
        <div>{result.bookAuthor}</div>
        <div
          style={{
            fontSize: "0.8em",
            color: colors.grey,
            textTransform: "uppercase",
            marginTop: "1em",
          }}
        >
          {result.releasedOn && (
            <div>
              <IoTimeOutline
                style={{
                  marginRight: ".5em",
                  verticalAlign: "-0.12em",
                }}
              />
              Sorti en {new Date(result.releasedOn).getFullYear()}
            </div>
          )}
          {result.author?.name && (
            <div>
              <IoPersonCircleOutline
                style={{
                  marginRight: ".5em",
                  verticalAlign: "-0.12em",
                }}
              />
              {result.author.name}
            </div>
          )}
        </div>
      </div>
    </Book>
  )
}
