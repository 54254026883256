import styled from "styled-components"
import { colors } from "./styles"

const CriteriaList = styled.div`
  ul {
    list-style: none;
    padding-left: 0;
    text-transform: uppercase;
    font-weight: 600;
    color: ${colors.yellow};

    @media (min-width: 1000px) {
      column-count: ${(props) => (props.big ? 2 : 1)};
    }

    ul {
      list-style: none;
      padding-left: ${(props) => (props.big ? "35px" : "20px")};
      font-weight: 400;
      text-transform: none;
      column-count: 1;
      color: ${colors.greyDark};

      a {
        color: ${colors.greyDark};

        &:hover {
          color: ${colors.yellowLight};
        }
      }
    }
  }
`

export default CriteriaList
