import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { fetchApi } from "./utils"

export default function Themes({ className, themes }) {
  const [finalThemes, setFinalThemes] = useState([])

  const setFilteredThemes = (themes) => {
    setFinalThemes(themes.filter((theme) => !theme.slug.startsWith("ibb")))
  }

  useEffect(() => {
    if (themes?.length) setFilteredThemes(themes)
    else fetchApi("/themes", setFilteredThemes)
  }, [themes])

  return (
    <ul className={className}>
      {finalThemes.map((theme) => (
        <li key={theme.id}>
          <Link to={"/recherche?theme=" + theme.slug}>{theme.name}</Link>
          {theme.children && <Themes themes={theme.children} />}
        </li>
      ))}
    </ul>
  )
}
