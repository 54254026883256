import { Link } from "gatsby"
import { lighten } from "polished"
import React from "react"
import styled from "styled-components"
import BookResult from "./BookResult"
import CriteriaList from "./CriteriaList"
import { colors } from "./styles"
import Themes from "./Themes"
import WithSidebar from "./WithSidebar"

const SearchResultsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5rem;
  row-gap: 3rem;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }

  a:hover {
    background: ${lighten(0.5, colors.grey)};
  }
`

const SearchCriteriaLink = styled(Link)`
  color: inherit;

  &:hover {
    color: ${colors.yellowLight};
  }
`

export default function BookResults({ title, titleExtra, results, noResult }) {
  return (
    <WithSidebar
      main={
        <section>
          <h1>
            {titleExtra && (
              <span
                style={{
                  display: "block",
                  fontSize: "0.7em",
                  color: colors.yellow,
                }}
              >
                {titleExtra}
              </span>
            )}
            {title}
          </h1>
          {results.length ? (
            <SearchResultsGrid>
              {results.map((result) => (
                <BookResult result={result} />
              ))}
            </SearchResultsGrid>
          ) : (
            <div
              style={{
                fontSize: "1.2em",
                textAlign: "center",
              }}
            >
              {noResult}
            </div>
          )}
        </section>
      }
      side={
        <div>
          <CriteriaList>
            <h2>
              <SearchCriteriaLink to="/recherche#themes">
                Rechercher par thème
              </SearchCriteriaLink>
            </h2>
            <Themes />
            <h2>
              <SearchCriteriaLink to="/recherche#auteurs">
                Rechercher par auteur
              </SearchCriteriaLink>
            </h2>
            <h2>
              <SearchCriteriaLink to="/recherche#editeurs">
                Rechercher par éditeur
              </SearchCriteriaLink>
            </h2>
          </CriteriaList>
        </div>
      }
    />
  )
}
